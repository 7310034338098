<template>
  <vca-card class="shadowed">
    <div
      :key="index"
      v-for="(res, index) in value.activities.slice().reverse()"
    >
      <vca-card class="shadowed">
        <vca-row>
          <h3 first>
            {{ res.user.full_name }} ({{ datetime(res.modified.created) }})
          </h3>
          <h3 last class="activity_status">{{ res.status }}</h3>
        </vca-row>
        <br />{{ res.comment }}
      </vca-card>
    </div>
  </vca-card>
</template>
<script>
export default {
  name: "TakingEditActivity",
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>
<style lang="scss">
.activity_status {
  text-align: right;
}
</style>
