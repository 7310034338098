<template>
  <vca-card>
    <h2>{{ $t("takings.edit.header") }}</h2>
    <TakingEdit />
  </vca-card>
</template>
<script>
import TakingEdit from "@/components/finance/takings/TakingEdit.vue";
export default {
  name: "TakingsEdit",
  components: {
    TakingEdit,
  },
  created() {
    this.$store.dispatch({ type: "crews/public" });
    this.$store.dispatch({
      type: "takings/getByID",
      data: this.$route.params.id,
    });
  },
};
</script>
